import React from 'react'

import { dashifyString } from '../../lib/helpers'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.75, 1.75),
    backgroundColor: props => (props.highlight ? theme.palette.primary.highlight : null),
    color: props => (props.highlight ? theme.palette.primary.contrastText : null),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  noContentRadius: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  title: {
    // Apply bold on highlighted titles
    fontWeight: props => (props.highlight ? 500 : theme.typography.h4.fontWeight)
  },
  subtitle: {
    fontWeight: 500
  }
}))

export default function NonExpansiblePanelSummary(props) {
  const classes = styles(props)

  return props.title ? (
    <>
      <div
        aria-controls={dashifyString(props.title) + '-content'}
        id={dashifyString(props.title) + '-header'}
        className={classes.root + (props.noContent ? ' ' + classes.noContentRadius : '')}
      >
        <Typography
          variant={props.highlight ? 'h3' : 'h4'}
          component="h3"
          classes={{ root: classes.title }}
        >
          {props.title}
        </Typography>
        {props.subtitle ? (
          <Typography variant="caption" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        ) : null}
      </div>
      {props.noContent ? null : <Divider />}
    </>
  ) : null
}
