import React from 'react'
import Link from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const styles = makeStyles(theme => ({
  root: {
    lineHeight: 2
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    textDecoration: 'none',
    color: 'inherit',
    paddingBottom: theme.spacing(0.5)
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    color: theme.palette.grey[500],
    display: 'block',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flex: '0 0 auto'
  },
  icon: {
    width: '14px',
    height: '14px',
    color: theme.palette.primary.main,
    marginLeft: 'auto'
  }
}))

const searchResultLink = props => {
  const classes = styles()

  return (
    <Typography className={classes.root} variant="body2" component="div">
      <Link to={props.to} color="inherit" underline="none" className={classes.link}>
        <span className={classes.title}>{props.title}</span>
        {props.subtitle ? (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        ) : null}
        <ArrowForwardIosIcon className={classes.icon} />
      </Link>
    </Typography>
  )
}

export default searchResultLink
