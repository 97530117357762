import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import NonExpansiblePanelSummary from './NonExpansiblePanelSummary'
const styles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    // Add margin when we are showing multiple panels
    '& + .MuiPaper-root': {
      marginTop: theme.spacing(2)
    },
    '& + .CardLink-root': {
      marginTop: theme.spacing(2)
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: props => (props.highlightContent ? theme.palette.primary.highlight : null),
    color: props => (props.highlightContent ? theme.palette.primary.contrastText : null),
    borderRadius: props => (props.highlightContent ? theme.shape.borderRadius : null),
    boxShadow: props => (props.highlightContent ? theme.shadows[3] : null)
  }
}))

export default function NonExpansiblePanel(props) {
  const classes = styles(props)
  const panelContent = props.highlightContent ? '' : ' Panel-content'
  const noContent = props.children ? null : true

  return (
    <Paper className={classes.root}>
      <NonExpansiblePanelSummary
        title={props.title}
        subtitle={props.subtitle}
        highlight={props.highlight}
        noContent={noContent}
      />

      {props.children ? (
        <div className={classes.content + panelContent}>{props.children}</div>
      ) : null}
    </Paper>
  )
}
